$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        maxWidth: $(window).width() * 0.8,
        maxHeight: $(window).height() * 0.8,
        shrinkFactor:1,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC.
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if ( breakpoint['medium'] ) {
        $('.page-navi').stick_in_parent({
            parent: 'body'
        });
    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');


    /**
     * PREVENTS CONTENT SCROLLING AFTER TOGGELING
     */
    // naviToggled.onchange('change');

    // change top-line on scroll 

    // $(window).scroll(function() {
    //     if($(this).scrollTop() > 200){
    //         $('.top-line').addClass('fixed');
    //     }else{
    //          $('.top-line').removeClass('fixed');
    //     }
    // });

    // Slick slider
    if ( breakpoint['medium'] ) {
       $('#main-slider').slick({
           arrows: false,
           dots:false,
           autoplay:true,
           infinite:true,
           slidesToShow:1,
           slidesToScroll:1,
           speed:1000,
           fade: true
       });
       $('#slider-nav').slick({
         arrows: false,
         slidesToShow: 9,
         slidesToScroll: 1,
         asNavFor: '#main-slider',
         vertical:true,
         dots: false,
         focusOnSelect: true,
         infinite:true
       });
    }    

    // button
    $(".weiter").click(function() {
        $('html, body').animate({
            scrollTop: $("#main").offset().top
        }, 2000);
    });   

    // Parallax animations

    var  $topLine =  $('.top-line');

    if ($(window).innerWidth() <= 974) {
        var mainTl2 = new TimelineMax({delay: 1.5});
    
        TweenMax.set('.overlay', {opacity: 1});
        TweenMax.set('.left-bg', {height: 0});
        TweenMax.set('.right-bg', {height: 0});
        TweenMax.set('.left-img', { opacity:0, y: '100%'});
        TweenMax.set('.top-small-img', {opacity:0, y: '100%'});
        TweenMax.set('.right-img', { opacity:0, y: '100%'});
        TweenMax.set('.left-col a', { opacity:0, y: '30px'});
        TweenMax.set('.right-col figcaption', { opacity:0, y: '-30px'});
        // TweenMax.set('.siel', { opacity:0, scale:0});
        // TweenMax.set('.top-line', { opacity:0, y: '-100px'});
        TweenMax.set('.branding', { opacity:0, scale:.7});
        // TweenMax.set('.page-navi', { height:0 });
        // TweenMax.set('.headline', { opacity:0, x:'100%'});

        mainTl2
        .to('.overlay', 0.5, {opacity: 0, visibility: 'hidden'})
        .to('.left-bg',1,{ease: Power1.easeInOut, height:'100%'})
        .to('.right-bg',1,{ease: Power1.easeInOut, height:'100%'},"-=1")
        .to('.left-img',1,{ease: Power1.easeInOut, opacity:1, y: '0%'})
        .to('.top-small-img', 1, {ease:Power1.easeInOut, opacity:1, y: '0%'}, "-=0.5")
        .to('.right-img',1,{ease: Power1.easeInOut, opacity:1, y: '0%'},"-=0.8")
        // .to('.top-line',0.7,{opacity:1, y: '0'},"-=0.8")
        .to('.left-col a',0.5,{ease: Power1.easeInOut, opacity:1, y: '0'},"-=0.7")
        .to('.right-col figcaption',0.5,{ease: Power1.easeInOut, opacity:1, y: '0'})
        // .to('.siel',0.5,{opacity:1, scale:1})
        .to('.branding',1,{ease: Sine.easeIn, opacity:1, scale:1}, "-=3.5")
        // .to('.page-navi',1,{height:100% })
        // .to('h2', 1, {opacity: 0, rotationX: 180, rotationY: -360})
        // .to('.para', 1, {rotationY: 180, rotationY: -360, onComplete: endStory});
    }

    if ($(window).innerWidth() >= 975) {
        var mainTl = new TimelineMax();
    
        TweenMax.set('.overlay', {opacity: 1});
        TweenMax.set('.left-bg', {height: 0});
        TweenMax.set('.right-bg', {height: 0});
        TweenMax.set('.left-img', { opacity:0, y: '100%'});
        TweenMax.set('.top-small-img', {opacity:0, y: '100%'});
        TweenMax.set('.right-img', { opacity:0, y: '100%'});
        TweenMax.set('.left-col a', { opacity:0, y: '30px'});
        TweenMax.set('.right-col figcaption', { opacity:0, y: '-30px'});
        TweenMax.set('.siel', { opacity:0, scale:0});
        TweenMax.set('.top-line', { opacity:0, y: '-100px'});
        TweenMax.set('.branding', { opacity:0, scale:.7, x:'-50%'});
        // TweenMax.set('.page-navi', { height:0 });
        // TweenMax.set('.headline', { opacity:0, x:'100%'});

        mainTl
        .to('.overlay', 0.5, {opacity: 0, visibility: 'hidden'})
        .to('.left-bg',1,{ease: Power1.easeInOut, height:'100%'})
        .to('.right-bg',1,{ease: Power1.easeInOut, height:'100%'},"-=1")
        .to('.left-img',1,{ease: Power1.easeInOut, opacity:1, y: '0%'})
        .to('.top-small-img', 1, {ease:Power1.easeInOut, opacity:1, y: '0%'}, "-=0.5")
        .to('.right-img',1,{ease: Power1.easeInOut, opacity:1, y: '0%'},"-=0.8")
        .to('.top-line',0.7,{opacity:1, y: '0'},"-=0.8")
        .to('.left-col a',0.5,{ease: Power1.easeInOut, opacity:1, y: '0'},"-=0.7")
        .to('.right-col figcaption',0.5,{ease: Power1.easeInOut, opacity:1, y: '0'})
        .to('.siel',0.5,{opacity:1, scale:1})
        .to('.branding',1,{ease: Sine.easeIn, opacity:1, scale:1})
        // .to('.page-navi',1,{height:100% })
        // .to('h2', 1, {opacity: 0, rotationX: 180, rotationY: -360})
        // .to('.para', 1, {rotationY: 180, rotationY: -360, onComplete: endStory});
    }

    // Magic Scroll

    // var controller = new ScrollMagic.Controller({
    //     triggerElement: ".main"
    // });

    // Animate elements in viewport
    var content1Tl = new TimelineMax();
    TweenMax.set('.content-1 .image-left', {opacity:0, y: '100%'});
    TweenMax.set('.content-1 .image-right', {opacity:0, y: '100%'});

    $(window).scroll(function() {
        if ($('.content-1').isOnScreen(0.2, 0.2)) {
            content1Tl
            .to('.image-left',0.5,{ease: Power1.easeInOut, opacity:1, y: '0%'}, "-=0.5")
            .to('.image-right',1,{ease: Power1.easeInOut, opacity:1, y: '0%'})
        }
    });

    var teaserTl = new TimelineMax();

    TweenMax.set('.teaser img', {opacity:0, x: '-100%'});

    $(window).scroll(function() {
        if ($('.teaser-area').isOnScreen(0.7,0.7)) {
            teaserTl
            .to('.teaser img', 1, {ease:Power1.easeInOut, opacity:1, x: '0%'})
        }
    });

    var content2Tl = new TimelineMax();
    TweenMax.set('.content-2 img', {opacity:0, x: '-100%'});

    $(window).scroll(function() {
        if ($('.content-2').isOnScreen(0.2, 0.2)) {
            content2Tl
            .to('.content-2 img',1,{ease:Power1.easeInOut, opacity:1, x: '0%'})
        }
    });

    var content3Tl = new TimelineMax();
    TweenMax.set('.content-3 figure .lightbox-image', {opacity:0, y: '100%'});

    $(window).scroll(function() {
        if ($('.content-3').isOnScreen(0.2, 0.2)) {
            content3Tl
            .to('.content-3 figure .lightbox-image',1,{ease:Power1.easeInOut, opacity:1, y: '0%'})
        }
    });

    var imageGallery = new TimelineMax();
    TweenMax.set('.image-gallery .image-item .lightbox-image', {opacity:0, x: '-100%'});
    // TweenMax.set('.image-gallery .cms-image-item', {opacity:0, x: '-100%'});

    $(window).scroll(function() {
        if ($('.image-gallery').isOnScreen(0.2,0.2)) {
            imageGallery
            .to('.image-gallery .image-item .lightbox-image', 1, {ease:Power1.easeInOut, opacity:1, x:'0%'})
        }
    });

    // checkbox - not clickable together
    $('.checkbox').on('change', function() {
        $('.checkbox').not(this).prop('checked', false);  
    }); 

    // fixed navigation
    $(window).scroll(function() {
        if ($(this).scrollTop() > 1) {  
            $('.top-line').addClass("sticky");
        }
        else {
            $('.top-line').removeClass("sticky");
        }
    });

});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/